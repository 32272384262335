'use client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { NextRouter, ReadonlyURLSearchParams, useRouter } from 'next/navigation'
import { useContext, useEffect } from 'react'
import useForm from './../../hooks/useForm'
import LoginForm from '@/modules/auth/login'
import { AuthContext } from "@/modules/auth/context"
import useQueryParams from "@/hooks/useQueryParams"

export const redirectionHandler = (type, router: ReadonlyURLSearchParams) => {
    const redirect = router.get('redirect')
    switch (type) {
        case 'APPLICANT':
            window.location.href = `${process.env.NEXT_PUBLIC_USER_URL}/${redirect || 'dashboard'}`
            break
        case 'ORG_INTERNAL_MEMBER':
            window.location.href = `${process.env.NEXT_PUBLIC_ORG_URL}/${redirect || '/welcome'}`
            break
        case 'ORG_CLIENT':
            window.location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/${redirect || 'welcome'}`
    }
}

const Login = () => {
    const router = useRouter()
    const routerQuery = useQueryParams()

    const { post, state: loginFormState, resetStates } = useForm('/login')

    const { setShow2FA } = useContext(AuthContext)
    const loginFormBusy = loginFormState.busy
    const loginFormStatusCode = loginFormState.statusCode
    const loginFormResult = loginFormState.result



    useEffect(() => {
        if (loginFormBusy || !loginFormStatusCode) return

        if (loginFormResult?.two_factor) {
            setShow2FA(true)
            router.push(`${process.env.NEXT_PUBLIC_ORG_URL}/login/two-factor`)
            return
        }

        if (loginFormStatusCode.toString().startsWith('2')) {
            redirectionHandler(loginFormResult?.type, routerQuery)
        }
    }, [loginFormBusy, loginFormStatusCode, loginFormResult])


    return <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENTID}>
        <LoginForm submit={post} redirectionHandler={redirectionHandler} formState={loginFormState} resetStates={resetStates} />
    </GoogleOAuthProvider>

}

export default Login
