import { Warning } from 'phosphor-react'
import React from 'react'
import { formState } from '../../../apps/organization/hooks/useForm'
import { _isObjEmpty } from '../../aw-utils/src/util'

const FormErrors = React.memo<{ state: formState; className?: string }>(({ state, className = '' }) => {
    if (state.busy || !state.hasError) return null
    if (!state.hasError && !state?.message) return null

    return (
        <div className={`${className} mb-3 rounded-md bg-red-50 p-4 shadow shadow-red-200 hover:shadow-none`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <Warning className="animate-shake-horizontal h-5 w-5 text-red-500" weight="fill" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{state.message ? state.message : 'Whoops! Something went wrong.'}</h3>

                    {!_isObjEmpty(state.errors) && Object.keys(state.errors).length > 1 && (
                        <ul className="mt-3 list-inside list-disc text-sm text-red-600">
                            {Object.entries(state.errors).map(([field, msg]) => (
                                <li key={field}>{msg}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
})

export default FormErrors
