'use client'
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";
function useQueryParams() {

    const searchParams = useSearchParams();
    const router = useRouter()
    const pathname = usePathname()

    function push({ path, query }: { path?: string, query: { [key: string]: any } }) {
        const params = new URLSearchParams(searchParams?.toString())
        for (const key in query) {
            if (query.hasOwnProperty(key)) {
                const value = query[key]
                params.set(key, value)
            }
        }
        if (path) {
            router.push(path + '?' + params.toString(), { scroll: false, shallow: true } as any)

        } else {
            router.push(pathname + '?' + params.toString(), { scroll: false, shallow: true } as any)
        }
    }

    function get(key: string) {
        return searchParams?.get(key) || undefined
    }

    function getAll() {
        const current = new URLSearchParams(searchParams?.toString())
        let query: { [key: string]: string } = {}
        current.forEach((value, key) => {
            query[key] = value
        })
        return query
    }

    function _delete(type: string | string[]) {
        const current = new URLSearchParams(searchParams?.toString())
        if (Array.isArray(type)) {
            type.forEach(element => {
                current.delete(element)

            });
        } else {
            current.delete(type)
        }
        router.push(pathname + '?' + current.toString())

        router.push(pathname + '?' + current.toString(), { scroll: false, shallow: true } as any)
    }

    function deleteAllQueries(params: string[]) {
        const current = new URLSearchParams(searchParams)
        params.forEach(param => {
            current.delete(param)
        })

        router.push(pathname + '?' + current.toString(), { scroll: false, shallow: true } as any)
    }

    function reset() {
        router.replace(pathname, { scroll: false, shallow: true } as any)
    }

    return {
        push,
        getAll,
        reset,
        get,
        _delete,
        deleteAllQueries,
        searchParams: searchParams.toString()
    }
}

export default useQueryParams