import useTheme from '../../aw-hooks/src/useTheme'

import clsx from 'clsx'
import React from 'react'

type InputProps = {
    soft?: boolean
    className?: string
    size: keyof typeof sizes
    checked:boolean
}

const sizes = { sm: 'h-4 w-4', lg: 'h-6 w-6', xl: 'h-8 w-8' }

const Checkbox: React.FC<InputProps> = receivedProps => {
    const { theme } = useTheme()
    const { soft = false, className = '', size = 'sm', ...props } = receivedProps

    const defaultClass = ` border border-gray-300 rounded-sm bg-white transition duration-200 outline-green float-left mr-2 cursor-pointer text-indigo-60 outline-indigo-600 `

    const ringClasses = 'focus:ring-indigo-500 ring-indigo-300'

    const softVariant =
        'border-transparent shadow hover:shadow-sm ' +
        'accent-indigo-100 text-indigo-900 hover:bg-indigo-200 shadow-indigo-400/60 checked:bg-indigo-300/80' +
        ' ' +
        ringClasses

    const baseVariant =
        'text-indigo-500 hover:bg-indigo-300 checked:accent-indigo-500   ' +
        ringClasses +
        ' ' +
        'dark:bg-indigo-500 dark:highlight-white/20 dark:hover:bg-indigo-400'

    const accent = soft ? softVariant : baseVariant

    return <input className={clsx(className, defaultClass, ringClasses, accent, sizes[size])} type="checkbox" {...props} />
}

export default Checkbox
