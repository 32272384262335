import useForm from '@/hooks/useForm'
import { Checkbox, FieldError, FormErrors, Input, Label } from '@aw/design-system'
import Button from '@/components/Common/Button'
import Heading from '@/components/Common/Heading'

import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState, useRef } from 'react'
import toast from 'react-hot-toast'
import { GoogleLogin } from '@react-oauth/google';


import axios from "@/lib/axios"
import useQueryParams from "@/hooks/useQueryParams"
type creditProps = {
    access_token: string
}

export default function LoginForm({ formState, submit, resetStates, redirectionHandler }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(true)
    const { post, state } = useForm(`/login-with-google`)

    useEffect(() => {
        resetStates()
    }, [email, password])

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault()
        await axios.get('/sanctum/csrf-cookie')
        console.log(process.env.NEXT_PUBLIC_MANUAL_LOGIN_COOKIE)
        process.env.NEXT_PUBLIC_MANUAL_LOGIN_COOKIE && localStorage.setItem('csrf', prompt('enter x-xsrf-token'))
        submit({ remember, email, password })
    }

    const router = useQueryParams()

    const socialIntegration = async (credentialResponse: creditProps) => {
        await axios.get('/sanctum/csrf-cookie')
        post(
            { token: credentialResponse.credential, remember: remember },
            {
                onSuccess: (res) => {
                    redirectionHandler(res.data?.type, router)
                },
                onError: (response) => { console.error(response); toast.error(response.data ? response.data.message : 'Something went wrong') }
            }
        )
    }

    const facebookIntegration = () => {

    }


    const responseFacebook = (response) => {
        console.log(response);
    }



    return (
        <div
            className="flex flex-col items-center gap-6 w-full max-w-[20rem]"
        >
            {/* COMPANY LOGO */}
            <div className="flex justify-center w-full select-none">
                <img src='/static/assets/logo.svg' alt='abroadworks-logo' />
            </div>
            <Heading size="h3" className="text-center">
                Log in to  your account
            </Heading>



            <FormErrors state={formState} className="mb-2 -mt-6" />

            <form onSubmit={submitForm} className="font-karla w-full">
                <div className="w-full">
                    <Label htmlFor="email" required className="!text-sm font-medium text-neutralgray-10">
                        Email address
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email address"
                        value={email}
                        required
                        disabled={formState.busy}
                        error={formState.isValidationError && formState.errors.email}
                        onChange={event => setEmail(event.target.value)}
                        autoFocus
                        className="shadow-xs mt-2 w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9"></Input>
                    <FieldError state={formState} name="email" />
                </div>
                <div className="my-4 w-full">
                    <Label className="!text-sm font-medium text-neutralgray-10" htmlFor="password" required>
                        Password
                    </Label>
                    <Input
                        type="password"
                        id="password"
                        value={password}
                        placeholder="Password"
                        disabled={formState.busy}
                        error={formState.isValidationError && formState.errors.password}
                        onChange={event => setPassword(event.target.value)}
                        required
                        className="shadow-xs mt-2 w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9"></Input>
                    <FieldError state={formState} name="password" />
                </div>
                <div className="flex justify-between">
                    <div className="mb-6 flex items-center">
                        <Checkbox size="sm" checked={remember} onChange={e => setRemember(!remember)} />

                        <label htmlFor="remember" className="font-karla text-sm font-normal text-neutral-500">
                            Stay signed in
                        </label>
                    </div>
                    <Link
                        href="/forgot-password"
                        className="font-karla text-sm font-normal text-neutralgray-10 hover:text-neutralgray-12">
                        Forgot Password?
                    </Link>
                </div>

                <Button
                    size="md"
                    type={submit}
                    color="primary"
                    className="!text-xs group !w-full"
                    disabled={formState.busy}
                    loading={formState.busy}
                    loadingText="Logging In">
                    <div className="flex w-full items-center justify-center">
                        <span className="mr-2 text-xs">Continue with email</span>
                        <svg
                            className="ease transition group-hover:translate-x-1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                id="arrow-right-light"
                                d="M15.8475 8.45072L9.9221 15.7834C9.80423 15.9292 9.61136 16 9.48278 16C9.34042 16 9.19748 15.9427 9.08418 15.8268C8.84228 15.5795 8.82435 15.1577 9.04425 14.8857L14.0688 8.66736H0.59254C0.264982 8.66736 0 8.36927 0 8.03826C0 7.70725 0.265054 7.33416 0.59254 7.33416H14.0685L9.04311 1.11391C8.8232 0.841815 8.84113 0.419814 9.08304 0.172754C9.32552 -0.0733059 9.69987 -0.0550992 9.9206 0.218333L15.846 7.55097C16.0511 7.80495 16.0511 8.19657 15.8475 8.45072Z"
                                fill="#FCFCFC"
                            />
                        </svg>
                    </div>
                </Button>


            </form>
            <div className="flex w-full items-center gap-2">
                <div className="h-[0.0625rem] flex-grow  bg-neutralgray-8"></div>
                <div className="font-karla w-max px-2 text-center text-sm font-medium text-neutralgray-8">
                    Or Continue with Email
                </div>
                <div className="h-[0.0625rem] flex-grow  bg-neutralgray-8"></div>
            </div>
            <div className="flex w-full justify-center">
                <SocialAuth socialIntegration={socialIntegration} responseFacebook={responseFacebook} />
            </div>

        </div>

    )
}

function SocialAuth({ socialIntegration, responseFacebook }) {


    return (
        <div className="flex w-full flex-col gap-4 ml-3">
            <GoogleLogin
                width={314}
                onSuccess={async (credentialResponse) => {
                    await socialIntegration(credentialResponse);
                }
                }
                onError={() => {
                    console.error('Login Failed');
                }}
            />
        </div>
    )
}

